import React from 'react';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  PencilIcon,
  PlusIcon,
  AlertIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  Grid,
  Checkbox,
  Banner,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Button,
  InputLabel,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

import RentStep from './RentStep';
import messages from '../messages';
import { validateLeaseRent as validate } from '../validate';
import { useRentSteps, getUpdatedStepEndDate } from './hooks';

const LeaseRentAmountModal = ({
  intl,
  open,
  closeModal,
  leaseRentPercentage,
  unit,
  receivingAssistance,
  affordableValues,
  applicableRents,
  lease,
  property,
  hud236BasicRent,
  hud236MarketRent,
  renewalLeaseStartDate,
  renewalIsRenewalComplete,
  refreshLDT,
}) => {
  const flags = useFlags();
  const {
    rentSteps,
    setRentSteps,
    resetRentSteps,
    saveRentSteps,
    receivingAsstState,
    setReceivingAsstState,
    rentStepsSaving,
    setRentStepsToDelete,
    setRentStepsToCreate,
    setRentStepsToUpdate,
    rentStepsToUpdate,
  } = useRentSteps({
    lease,
    receivingAssistance,
    closeModal,
    refreshLDT,
    open,
  });

  function handleModalClose() {
    closeModal();
    resetRentSteps();
  }
  const rentStepHasError = rentSteps.some(
    (step) => !!step.errors?.amount || !!step.errors?.startDate,
  );
  const rentStepHasMissingRequiredFields = rentSteps.some((step) => {
    const res = !step.amount || !step.startDate;
    return res;
  });
  const actions = (
    <>
      <Button
        variant="text"
        onClick={handleModalClose}
        disabled={rentStepsSaving}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={() => {
          saveRentSteps();
        }}
        disabled={
          rentStepHasError ||
          rentStepHasMissingRequiredFields ||
          rentStepsSaving
        }
      >
        Save
      </Button>
    </>
  );
  function addRentStep() {
    setRentSteps((prev) => {
      const newRentStep = {
        id: prev.length,
        idx: prev.length,
        startDate: null,
        endDate: null,
      };
      setRentStepsToCreate((prev) =>
        Array.from(new Set([...prev, newRentStep.id])),
      );
      return [...prev, newRentStep];
    });
  }
  function onRentStepChange({ id, startDate, amount, name, idx }) {
    setRentSteps((prevRentSteps) => {
      const isExistingRentStep = typeof id === 'string';
      const updatedRentStepPreviousState = prevRentSteps.find(
        (step) => step.idx === idx,
      );
      const isCurrentLastStep =
        updatedRentStepPreviousState.idx === prevRentSteps.length - 1;

      let nextInLine = !isCurrentLastStep
        ? prevRentSteps.find(
            (step) => step.idx === updatedRentStepPreviousState.idx + 1,
          )
        : null;

      if (name === 'date') {
        const priorRentStepPreviousState = prevRentSteps.find(
          (step) => step.idx === idx - 1,
        );
        const updatedRentStepEndDate = getUpdatedStepEndDate({
          stepStartDate: startDate,
          isCurrentLastStep,
          currentLeaseEndDate: lease.endDate,
          nextRentStepStartDate: nextInLine?.startDate,
          renewalOngoing: renewalIsRenewalComplete === false,
          renewalLeaseStartDate,
        });

        const updatedRentStepUpdatedState = {
          ...updatedRentStepPreviousState,
          startDate,
          endDate: updatedRentStepEndDate,
        };
        const priorRentStepUpdatedState = {
          ...priorRentStepPreviousState,
          endDate: startDate.clone().subtract(1, 'days'),
        };
        const updatedIds = [
          updatedRentStepUpdatedState.id,
          priorRentStepUpdatedState.id,
        ];

        if (isExistingRentStep) {
          setRentStepsToUpdate((prev) =>
            Array.from(new Set([...prev, ...updatedIds])),
          );
        }
        const prevRentStepsExcludingUpdatedSteps = prevRentSteps.filter(
          (step) => !updatedIds.includes(step.id),
        );
        return _.orderBy(
          [
            ...prevRentStepsExcludingUpdatedSteps,
            updatedRentStepUpdatedState,
            priorRentStepUpdatedState,
          ],
          'idx',
          'asc',
        );
      } else {
        const updatedRentStepUpdatedState = {
          ...updatedRentStepPreviousState,
          amount,
          errors: {
            amount: validate({
              leasedRent: amount,
              leaseRentPercentage,
              receivingAssistance: receivingAsstState,
              affordableValues,
              applicableRents,
              lease,
              unit,
              intl,
              flags,
              selectedProperty: property,
              hud236BasicRent,
              hud236MarketRent,
            }).leasedRent,
          },
        };
        if (isExistingRentStep) {
          setRentStepsToUpdate((prev) =>
            Array.from(new Set([...prev, updatedRentStepUpdatedState.id])),
          );
        }

        const prevRentStepsExcludingUpdatedStep = prevRentSteps.filter(
          (step) => step.id !== id,
        );
        return _.orderBy(
          [...prevRentStepsExcludingUpdatedStep, updatedRentStepUpdatedState],
          'idx',
          'asc',
        );
      }
    });
  }
  function onDeleteRentStep({ id, idx }) {
    const isExistingRentStep = typeof id === 'string';

    setRentSteps((prevRentSteps) => {
      const rentStepToDelete = prevRentSteps.find((step) => step.id === id);
      const isCurrentLastStep =
        rentStepToDelete.idx === prevRentSteps.length - 1;

      if (isExistingRentStep) {
        setRentStepsToDelete((prev) => [...prev, rentStepToDelete.id]);
        if (rentStepsToUpdate.includes(rentStepToDelete.id)) {
          setRentStepsToUpdate((prev) =>
            prev.filter((stepId) => stepId !== rentStepToDelete.id),
          );
        }
      }
      setRentStepsToCreate((prev) => {
        const newCreateSteps = prev.filter((stepId) => stepId !== id);
        return newCreateSteps;
      });

      // Adjust end date of rent step just above the updated rent step
      const priorRentStepPreviousState = prevRentSteps.find(
        (step) => step.idx === idx - 1,
      );
      const updatedPriorRentStepEndDate = getUpdatedStepEndDate({
        stepStartDate: priorRentStepPreviousState.startDate,
        isCurrentLastStep,
        currentLeaseEndDate: lease.endDate,
        nextRentStepStartDate: prevRentSteps[idx + 1]?.startDate,
        renewalOngoing: renewalIsRenewalComplete === false,
        renewalLeaseStartDate,
      });
      const priorRentStepUpdatedState = {
        ...priorRentStepPreviousState,
        endDate: updatedPriorRentStepEndDate,
      };
      if (isExistingRentStep) {
        setRentStepsToUpdate((prev) =>
          Array.from(new Set([...prev, priorRentStepUpdatedState.id])),
        );
      }
      const rentStepsExcludingUpdatedPriorRentStep = prevRentSteps.filter(
        (step) => step.id !== priorRentStepPreviousState.id,
      );
      const updatedRentSteps = _.orderBy(
        [
          ...rentStepsExcludingUpdatedPriorRentStep,
          priorRentStepUpdatedState,
        ].filter((step) => step.id !== id), // Remove "deleted" rent step
        'idx',
        'asc',
      ).map((step, idx) => ({ ...step, idx }));
      return updatedRentSteps;
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      icon={<PencilIcon />}
      title={'Lease Rent Amount'}
      actions={actions}
      ModalProps={{
        PaperProps: {
          sx: {
            width: {
              xs: 'calc(100% - 32px)',
              sm: 600,
            },
          },
        },
      }}
    >
      <Grid container spacing={1} columnSpacing={3}>
        {/* Header */}
        <>
          <Grid item xs={4}>
            <Typography variant="labelLarge">Start Date</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="labelLarge">End Date</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="labelLarge">Lease Rent Amount</Typography>
          </Grid>
        </>
        {/* Rent Step Component */}
        {rentSteps.map(({ id, startDate, endDate, amount, errors }, idx) => {
          return (
            <RentStep
              key={id}
              id={id}
              idx={idx}
              initialStartDate={startDate}
              initialEndDate={endDate}
              initialAmount={amount}
              onChange={onRentStepChange}
              onDelete={onDeleteRentStep}
              priorRentStep={rentSteps[idx - 1] ?? null}
              errors={errors}
              leaseEndDate={lease.endDate}
              renewalLeaseStartDate={renewalLeaseStartDate}
              renewalOngoing={renewalIsRenewalComplete === false}
              saving={rentStepsSaving}
            />
          );
        })}
        {/* Add Rent Button */}
        <>
          <Grid item xs={12} paddingBottom={1}>
            <Button
              variant="text"
              startIcon={<PlusIcon />}
              onClick={addRentStep}
              disabled={
                rentStepHasError ||
                rentStepHasMissingRequiredFields ||
                rentStepsSaving
              }
            >
              Add Rent
            </Button>
          </Grid>
        </>
        {/* Housing Asst */}
        {affordableValues.isAffordable ? (
          <>
            <Grid item xs={12}>
              <InputLabel>Receives Housing Assistance</InputLabel>
              <Checkbox
                label="Yes"
                checked={receivingAsstState}
                onChange={(e) => {
                  setReceivingAsstState(e.target.checked);
                }}
              />
            </Grid>
          </>
        ) : null}
        {/* Banner */}
        <>
          <Grid item xs={12}>
            <Banner
              text={
                <Typography sx={{ textWrap: 'wrap' }} color={'text.secondary'}>
                  {intl.formatMessage(messages.leaseRentBannerText)}
                </Typography>
              }
              color="lightGrey"
              icon={<AlertIcon />}
              hasBorder={false}
              BoxProps={{
                sx: {
                  height: 'auto',
                },
              }}
            />
          </Grid>
        </>
      </Grid>
    </Modal>
  );
};

export default LeaseRentAmountModal;
